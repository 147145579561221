<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card v-if="dialog">
      <v-toolbar dark :color="$primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Intervenção - {{MyObject.nome}} - {{MyObject.tipo_at}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
      </v-toolbar>
      <v-card-text class="d-flex justify-center">
        <ImageDialog />
        <v-col cols="8">
          <v-expansion-panels v-model="panel" flat>
            <v-expansion-panel v-for="(item,i) in getItems" :key="i">
              <v-expansion-panel-header>{{item.nome}} - {{item.data}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row class="d-block">
                    <label class="label-modal">Observações</label>
                    <p>{{item.obs || 'Sem observações'}}</p>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="foto in item.fotos"
                      :key="foto.nome"
                      class="d-flex child-flex"
                      cols="2"
                    >
                      <v-card flat tile class="d-flex">
                        <v-img
                          :src="`${$files}${foto.nome}`"
                          aspect-ratio="1"
                          class="grey lighten-2 pointer"
                          @click="openImage(`${$files}${foto.nome}`)"
                        >
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {
    ImageDialog: () => import("@/components/dialogs/ImageDialog")
  },
  data: () => ({
    dialog: false,
    items: [],
    panel: [],
    MyObject: {}
  }),
  computed: {
    getItems() {
      return this.items
    }
  },
  watch: {
    panel(val) {
      if (typeof val != "undefined") this.getImages()
    },
    dialog(val) {
      if (!val) {
        setTimeout(() => {
          this.panel = []
          this.MyObject = {}
        }, 200)
      }
    }
  },
  created() {
    this.$eventBus.$on("Intervencao", obj => {
      this.dialog = true
      this.MyObject = { ...obj }
      this.$eventBus.$emit("loader", true)
      this.$store
        .dispatch("intervencao/get", { id_at: obj.id_at })
        .then(res => {
          this.$eventBus.$emit("loader", false)
          this.items = res
        })
    })
  },
  methods: {
    openImage(path) {
      this.$eventBus.$emit("ImageDialog", {
        state: true,
        src: path
      })
    },
    getImages() {
      if (
        typeof this.getItems[this.panel] == "undefined" ||
        this.getItems[this.panel].hasOwnProperty("fotos")
      )
        return

      this.$store
        .dispatch("foto/get", {
          id_intervencao: this.getItems[this.panel].id_intervencao
        })
        .then(res => {
          this.getItems[this.panel].fotos = res
          this.$forceUpdate()
        })
    }
  }
}
</script>